import LocationEdit from "./LocationEdit";
import LocationCreate from "./LocationCreate";
import LocationDelete from "./LocationDelete";

export default class UserForms {
    constructor(){}
    static initFromDom(){
        LocationEdit.initFromDom();
        LocationCreate.initFromDom();
        LocationDelete.initFromDom();
    }
}