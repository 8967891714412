import AbstractForm from "./AbstractForm";

export default class LocationEdit extends AbstractForm{
    /**
     *
     * @param {jQuery} $main
     */
    constructor($main){
        super($main);
        console.log("init LocationEdit");
        let me=this;
        /**
         * Le conteneur pour les saisies utilisateur
         * @type {jQuery}
         */
        this.$locationUid=$main.find("input[name='locationUid']");

        this.$commercial_name=$main.find("input[name='commercial_name']");
        this.$owner_name=$main.find("input[name='owner_name']");
        this.$mark=$main.find("input[name='mark']");

        this.$open_daily_morning=$main.find("input[name='open_daily_morning']");
        this.$open_daily_afternoon=$main.find("input[name='open_daily_afternoon']");
        this.$open_daily_evening=$main.find("input[name='open_daily_evening']");

        this.$open_weekly_week=$main.find("input[name='open_weekly_week']");
        this.$open_weekly_saturday=$main.find("input[name='open_weekly_saturday']");
        this.$open_weekly_sunday=$main.find("input[name='open_weekly_sunday']");

        this.$adresse=$main.find("input[name='adresse']");
        this.$city=$main.find("input[name='city']");
        this.$zipcode=$main.find("input[name='zipcode']");
        this.$phone=$main.find("input[name='phone']");
        this.$mobile_phone=$main.find("input[name='mobile_phone']");
        this.$email=$main.find("input[name='email']");
        this.$email_contact=$main.find("input[name='email_contact']");
        this.$website=$main.find("input[name='website']");

        this.$lat_lng=$main.find("input[name='lat_lng']");

        this.$sell_floc_gascogne=$main.find("input[name='sell_floc_gascogne']");
        this.$sell_vin_gascogne=$main.find("input[name='sell_vin_gascogne']");
        this.$sell_armagnac=$main.find("input[name='sell_armagnac']");
        this.$sell_other=$main.find("input[name='sell_other']");

        this.$activity_visite=$main.find("input[name='activity_visite']");
        this.$activity_rando=$main.find("input[name='activity_rando']");
        this.$activity_musee=$main.find("input[name='activity_musee']");
        this.$activity_espace=$main.find("input[name='activity_espace']");
        this.$activity_art=$main.find("input[name='activity_art']");
        this.$activity_spectacles=$main.find("input[name='activity_spectacles']");
        this.$activity_hotellerie=$main.find("input[name='activity_hotellerie']");
        this.$activity_gite=$main.find("input[name='activity_gite']");
        this.$activity_atelier_culinaire=$main.find("input[name='activity_atelier_culinaire']");
        this.$activity_others=$main.find("input[name='activity_others']");

        this.$event_yearly=$main.find("input[name='event_yearly']");
        this.$event_weekend=$main.find("input[name='event_weekend']");
        this.$event_holiday=$main.find("input[name='event_holiday']");
        this.$event_summer=$main.find("input[name='event_summer']");
        this.$event_payant=$main.find("input[name='event_payant']");
        this.$event_free=$main.find("input[name='event_free']");
        this.$event_reservation=$main.find("input[name='event_reservation']");

        this.$archi_chateau=$main.find("input[name='archi_chateau']");
        this.$archi_classe=$main.find("input[name='archi_classe']");
        this.$archi_others=$main.find("input[name='archi_others']");

        this.$interest_chais=$main.find("input[name='interest_chais']");
        this.$interest_others=$main.find("input[name='interest_others']");

        this.$landscape_panorama=$main.find("input[name='landscape_panorama']");
        this.$landscape_pov=$main.find("input[name='landscape_pov']");
        this.$landscape_others=$main.find("input[name='landscape_others']");

        this.$pratice_label_bio=$main.find("input[name='pratice_label_bio']");
        this.$pratice_label_bio_progress=$main.find("input[name='pratice_label_bio_progress']");
        this.$pratice_label_biodynamie=$main.find("input[name='pratice_label_biodynamie']");
        this.$pratice_label_others=$main.find("input[name='pratice_label_others']");

        this.$pratice_agroeco_property_paturage=$main.find("input[name='pratice_agroeco_property_paturage']");
        this.$pratice_agroeco_property_ruches=$main.find("input[name='pratice_agroeco_property_ruches']");
        this.$pratice_agroeco_property_agroforesterie=$main.find("input[name='pratice_agroeco_property_agroforesterie']");
        this.$pratice_agroeco_property_other=$main.find("input[name='pratice_agroeco_property_other']");

        this.$pratice_agroeco_culture_mecanique=$main.find("input[name='pratice_agroeco_culture_mecanique']");
        this.$pratice_agroeco_culture_engrais_vert=$main.find("input[name='pratice_agroeco_culture_engrais_vert']");
        this.$pratice_agroeco_culture_organique=$main.find("input[name='pratice_agroeco_culture_organique']");
        this.$pratice_agroeco_culture_other=$main.find("input[name='pratice_agroeco_culture_other']");

        this.$tradition_welcome_by_onwer=$main.find("input[name='tradition_welcome_by_onwer']");
        this.$tradition_legacy=$main.find("input[name='tradition_legacy']");
        this.$tradition_installation=$main.find("input[name='tradition_installation']");
        this.$tradition_life_place=$main.find("input[name='tradition_life_place']");

        this.$modernity_new_building=$main.find("input[name='modernity_new_building']");
        this.$modernity_new_building_details=$main.find("input[name='modernity_new_building_details']");
        this.$modernity_new_chais=$main.find("input[name='modernity_new_chais']");
        this.$modernity_new_chais_details=$main.find("input[name='modernity_new_chais_details']");
        this.$modernity_new_technology=$main.find("input[name='modernity_new_technology']");
        this.$modernity_new_technology_details=$main.find("input[name='modernity_new_technology_details']");
        this.$modernity_new_generation=$main.find("input[name='modernity_new_generation']");
        this.$modernity_new_product=$main.find("input[name='modernity_new_product']");
        this.$modernity_new_marketing=$main.find("input[name='modernity_new_marketing']");

        this.$description=$main.find("textarea[name='description']");

        this.$main.on("submit",function(e){
            e.preventDefault();
            me.loading();

            let data = {};
            data.commercial_name = me.$commercial_name.val();
            data.owner_name = me.$owner_name.val();
            data.mark = me.$mark.val();

            data.open_daily_morning = me.$open_daily_morning.is(":checked");
            data.open_daily_afternoon = me.$open_daily_afternoon.is(":checked");
            data.open_daily_evening = me.$open_daily_evening.is(":checked");

            data.open_weekly_week = me.$open_weekly_week.is(":checked");
            data.open_weekly_saturday = me.$open_weekly_saturday.is(":checked");
            data.open_weekly_sunday = me.$open_weekly_sunday.is(":checked");

            data.adresse = me.$adresse.val();
            data.city = me.$city.val();
            data.zipcode = me.$zipcode.val();
            data.phone = me.$phone.val();
            data.mobile_phone = me.$mobile_phone.val();
            data.email = me.$email.val();
            data.email_contact = me.$email_contact.val();
            data.website = me.$website.val();

            data.lat_lng = me.$lat_lng.val();

            data.sell_floc_gascogne = me.$sell_floc_gascogne.is(":checked");
            data.sell_vin_gascogne = me.$sell_vin_gascogne.is(":checked");
            data.sell_armagnac = me.$sell_armagnac.is(":checked");
            data.sell_other = me.$sell_other.val();

            data.activity_visite = me.$activity_visite.is(":checked");
            data.activity_rando = me.$activity_rando.is(":checked");
            data.activity_musee = me.$activity_musee.is(":checked");
            data.activity_espace = me.$activity_espace.is(":checked");
            data.activity_art = me.$activity_art.is(":checked");
            data.activity_spectacles = me.$activity_spectacles.is(":checked");
            data.activity_hotellerie = me.$activity_hotellerie.is(":checked");
            data.activity_gite = me.$activity_gite.is(":checked");
            data.activity_atelier_culinaire = me.$activity_atelier_culinaire.is(":checked");
            data.activity_others = me.$activity_others.val();

            data.event_yearly = me.$event_yearly.is(":checked");
            data.event_weekend = me.$event_weekend.is(":checked");
            data.event_holiday = me.$event_holiday.is(":checked");
            data.event_summer = me.$event_summer.is(":checked");
            data.event_payant = me.$event_payant.is(":checked");
            data.event_free = me.$event_free.is(":checked");
            data.event_reservation = me.$event_reservation.is(":checked");

            data.archi_chateau = me.$archi_chateau.is(":checked");
            data.archi_classe = me.$archi_classe.is(":checked");
            data.archi_others = me.$archi_others.val();

            data.interest_chais = me.$interest_chais.is(":checked");
            data.interest_others = me.$interest_others.val();

            data.landscape_panorama = me.$landscape_panorama.is(":checked");
            data.landscape_pov = me.$landscape_pov.is(":checked");
            data.landscape_others = me.$landscape_others.val();

            data.pratice_label_bio = me.$pratice_label_bio.is(":checked");
            data.pratice_label_bio_progress = me.$pratice_label_bio_progress.is(":checked");
            data.pratice_label_biodynamie = me.$pratice_label_biodynamie.is(":checked");
            data.pratice_label_others = me.$pratice_label_others.val();

            data.pratice_agroeco_property_paturage = me.$pratice_agroeco_property_paturage.is(":checked");
            data.pratice_agroeco_property_ruches = me.$pratice_agroeco_property_ruches.is(":checked");
            data.pratice_agroeco_property_agroforesterie = me.$pratice_agroeco_property_agroforesterie.is(":checked");
            data.pratice_agroeco_property_other = me.$pratice_agroeco_property_other.val();

            data.pratice_agroeco_culture_mecanique = me.$pratice_agroeco_culture_mecanique.is(":checked");
            data.pratice_agroeco_culture_engrais_vert = me.$pratice_agroeco_culture_engrais_vert.is(":checked");
            data.pratice_agroeco_culture_organique = me.$pratice_agroeco_culture_organique.is(":checked");
            data.pratice_agroeco_culture_other = me.$pratice_agroeco_culture_other.val();

            data.tradition_welcome_by_onwer = me.$tradition_welcome_by_onwer.is(":checked");
            data.tradition_legacy = me.$tradition_legacy.is(":checked");
            data.tradition_installation = me.$tradition_installation.is(":checked");
            data.tradition_life_place = me.$tradition_life_place.is(":checked");

            data.modernity_new_building = me.$modernity_new_building.is(":checked");
            data.modernity_new_building_details = me.$modernity_new_building_details.val();
            data.modernity_new_chais = me.$modernity_new_chais.is(":checked");
            data.modernity_new_chais_details = me.$modernity_new_chais_details.val();
            data.modernity_new_technology = me.$modernity_new_technology.is(":checked");
            data.modernity_new_technology_details = me.$modernity_new_technology_details.val();
            data.modernity_new_generation = me.$modernity_new_generation.is(":checked");
            data.modernity_new_product = me.$modernity_new_product.is(":checked");
            data.modernity_new_marketing = me.$modernity_new_marketing.is(":checked");

            data.description = me.$description.val();

            api.me.locationEdit(
                me.$locationUid.val(),
                data,
                function (messages) {
                    me.displayMessage(messages);
                    setTimeout(function(){
                        document.location.reload(true);
                    },1000*1);
                },
                function(messages){
                    me.displayErrors(messages);
                }
            );
        });
    }

    static initFromDom(){
        $body.find("[data-model-edit-location='']").each(function () {
            $(this).attr("data-model-edit-location","init");
            new LocationEdit($(this));
        })
    }
}