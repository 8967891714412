import md5 from "../../../vendor/davidmars/pov-2018/_src/md5";


$body.on("change","[data-upload]",function(e){

    let $input=$(this);
    let total=$(this).get(0).files.length;
    for(let file of $(this).get(0).files){
        uploadChuncked(
            file,
            function(progress,apiResponse){ //cbProgress
                //console.log("uploading file "+progress);
                $input.trigger("upload-progress",[progress,apiResponse.json,file]);
            },
            function(apiResponse){ //cbComplete
                //receptionner l'uid du Filerecord
                //console.log("upload okkk json",apiResponse);
                $input.trigger("upload-fini",apiResponse.json);
                total--;
                if(total===0){
                    $input.trigger("upload-list-fini",apiResponse.json);
                }
            },
            function(apiResponse){ //cbError
                console.error("erreur upload",apiResponse);
                $input.trigger("upload-error",apiResponse);
                //me.$main.attr("state","error")
            },
            $input.attr("data-related-contenu-id"),
            $input.attr("data-related-contenu-property")
        );
    }
    e.target.value="";

});

/**
 * Upload un fichier en passant par l'api et en le découpant
 * @param {File} file
 * @param {function} cbProgress Renvoie la progression sous forme de : pourcentage, bytesuploadés, bytesTotaux
 * @param {function} cbComplete Renvoie un ApiReturn avec le fichier
 * @param {function} cbError Renvoie un ApiReturn avec les erreurs
 * @param {boolean} contenuid si défini le fichier est une ContenuFile et non un simple fileRecord, le fichier sera rataché au contenu mentionné
 */
function uploadChuncked(file,cbProgress,cbComplete,cbError,contenuid="", targetproperty=""){
    console.log("time a",new Date());


    //var sliceSize = 100000; // 100kb chunk sizes.;
    var sliceSize = 1048576; // 1MB chunk sizes.;
    console.log("upload size",file.size);
    var size, filename, filenametmp;
    filename=file.name;
    filenametmp=""+new Date().getTime()+file.name;
    size = file.size;

    if(size > 10000000)
    {
        let result ={};
        result.error = [];
        result.error.push();
        cbError("Le fichier est trop volumineux (10Mo max)");
        return;
    }

    //       3766497414 = 3.7 GO
    if(size> 1000000000){ //1GO
        sliceSize=1000000 * 10 //10MB
    }
    if(size> 2000000000){ //2GO
        sliceSize=1000000 * 50 //50MB
    }

    let uploadUrl = `${LayoutVars.rootUrl}/api/me/upload`;
    //let uploadUrl = LayoutVars.rootUrl+"/povApi/action/upload";

    //--------------1 va tester si le fichier existe déjà----------------
    console.log("time b",new Date());
    //var reader = new FileReader();
    //reader.readAsBinaryString(file);
    console.log("time c",new Date());

    function testFileIdentifier(binary){
        console.log("time md5 1",new Date());

        let localFileIdentifier = md5(binary);
        //let localFileIdentifier = "skip test";

        console.log("time md5 2",new Date());
        let url=uploadUrl;
        $.ajax({
            dataType: "json",
            url: uploadUrl,
            method:"get",
            data: {
                fileIdentifier:localFileIdentifier,
                filename:filename,
                contenuId:contenuid,
                targetProperty:targetproperty
            },
            cache:false,
            success: function(result){
                if(result.success){
                    if(result.json.record){
                        console.warn("le fichier existait déjà",result);
                        //cbComplete(result);
                        doUpload();
                    }else{
                        console.warn("on va uploader",result);
                        doUpload();
                    }
                }else{
                    console.error("erreur au moment d'uploader",result);

                }
            },
            error:function(response){
                console.error("oups testFileIdentifier",response);
                if(response.responseText){
                    //Xdebug.fromString(response.responseText)
                }
                cbError(response);
            }
        });
    }

    var reader = new FileReader();
    reader.onloadend = function (evt) {
        console.log("time d",new Date());
        let binary = evt.target.result;
        testFileIdentifier(binary);
        console.log("time e",new Date());
    };
    reader.readAsBinaryString(file);


    //testFileIdentifier(null);


    //-----------------2 l'upload---------------------------------

    function doUpload(){
        processFile();

        function processFile(e) {


            var start = 0;
            setTimeout(loop, 1);
            function loop() {
                var end = start + sliceSize;
                if (size - end < 0) {
                    end = size;
                }
                var s = slice(file, start, end);
                sendChunck(s, start, end,sliceSize,function(json){
                    if (end < size) {
                        start += sliceSize;
                        setTimeout(function(){
                            loop();
                        },10); //inititalement à 1000 - si on baisse ici on gagne bcp de temps mais est-ce que c'est bien?
                    }else{
                        cbComplete(json);
                    }
                });
            }
        }

        function sendChunck(piece, start, end,sliceSize,cbNextChunk) {
            var formdata = new FormData();
            var xhr = new XMLHttpRequest();
            //var apiurl=LayoutVars.rootUrl+"/povApi/action/upload?";
            var apiurl = `${LayoutVars.rootUrl}/api/me/upload?`;
            apiurl+="filename="+filename+"&";
            apiurl+="filenametmp="+filenametmp+"&";
            apiurl+="size="+size+"&";
            apiurl+="sliceSize="+sliceSize+"&";
            apiurl+="end="+end+"&";
            apiurl+="contenuId="+contenuid+"&";
            apiurl+="targetProperty="+targetproperty+"&";

            xhr.open('POST',apiurl,true);
            formdata.append('chunck', piece);
            xhr.addEventListener("error", function(){
                alert("error upload 1");

                let json=JSON.parse(xhr.response);
                cbError(json)
            }, false);
            xhr.onload = function(e) {
                let percentage=Math.floor(100/size*end);
                let json;
                try{
                    json=JSON.parse(xhr.response);
                }catch(e){
                    console.error("parse json error xhr is...");
                    console.error(xhr);
                }

                //console.log("upload recieve ",filename,""+percentage+"%",e);
                //console.log("upload recieve ",filename,""+percentage+"%",json,file);
                cbProgress(percentage,json,file);
                cbNextChunk(json);
                formdata=null;
                xhr=null;
            };
            xhr.send(formdata);
            //console.log("upload send ",filename,""+Math.floor(100/size*start)+"%");
        }
        /**
         * Formalize file.slice
         */
        function slice(file, start, end) {
            var slice = file.mozSlice ? file.mozSlice :
                file.webkitSlice ? file.webkitSlice :
                    file.slice ? file.slice : noop;

            return slice.bind(file)(start, end);
        }
        function noop() {}
    }

}