//ajout de contenufile

/**
 * Renvoie la barre de progression d'upload relative à un contenu
 * @param contenuId
 * @return {*}
 */
function $progressBarById(contenuId, targetProperty){
    let $el=$(".progress-bar[data-related-contenu-id='"+contenuId+"'][data-related-contenu-property='"+targetProperty+"']");
    if($el.length){
        return $el;
    }
    return null;
}
/**
 * Renvoie l'input file wrap relatif à un contenu
 * @param contenuId
 * @return {*}
 */
function $inputByContenuId(contenuId, targetProperty){
    let $el=$("input[type='file'][data-related-contenu-id='"+contenuId+"'][data-related-contenu-property='"+targetProperty+"']");
    if($el.length){
        return $el.closest(".upload-wrap");
    }
    return null;
}

/**
 * Renvoie la liste complète des fichiers (pour le refresh)
 * @param contenuId
 * @return {*}
 */
function $listFiles(contenuId){
    let $el=$("[data-pov-v-path='forms/contenu/contenu-files'][data-pov-vv-uid='contenu-"+contenuId+"']");
    if($el.length){
        return $el;
    }
    return null;
}

function displayProgress(contenuId, targetProperty,display=true,fileName="..."){
    let $bar=$progressBarById(contenuId,targetProperty);
    let $btn=$inputByContenuId(contenuId,targetProperty);
    //let $file=$btn.find(".file-name");
    if($bar && $btn){
        let $container=$bar.closest(".progress");
        if(display){
            $container.removeClass("invisible");
            $btn.addClass("uploading");
            //$file.text(fileName);
        }else{
            $container.addClass("invisible");
            $btn.removeClass("uploading");
            //$file.text("");
        }
    }
}

$body.on("upload-progress",'input[type="file"][data-related-contenu-id][data-related-contenu-property]',function(e,prog,json){
    console.log("prog",prog,json);
    if(json && json.contenu && json.target_property){
        let $prog=$progressBarById(json.contenu.id, json.target_property);
        if($prog) {
            $prog.css("width", prog + "%");
            displayProgress(json.contenu.id, json.target_property,true,json.filename+" "+prog+"%");
            $prog.removeClass("invisible");
        }
    }
});


$body.on("upload-error",'input[type="file"][data-related-contenu-id][data-related-contenu-property]',function(e,prog,json){

    $("[message]").html(prog);
});

$body.on("upload-fini",'input[type="file"][data-related-contenu-id][data-related-contenu-property]',function(e,json){
    console.log("upload fini",json);


    if(json && json.contenu && json.target_property){

        $body.find("[data-model-edit-location='init']").submit();

        //let $bar=$progressBarById(json.contenu.id, json.target_property);
        //let $container=$bar.closest(".progress");
        //$container.addClass("invisible");
        //window.Site.refreshContenus(json.contenu.uid);



        /*
        let $list=$listFiles(json.contenu.id);
        if($list) {
            $list.povRefresh();
        }
        */
    }
});
