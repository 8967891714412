import AbstractForm from "./AbstractForm";

export default class LocationCreate extends AbstractForm{
    /**
     *
     * @param {jQuery} $main
     */
    constructor($main){
        super($main);
        console.log("init LocationCreate");
        let me=this;
        /**
         * Le conteneur pour les saisies utilisateur
         * @type {jQuery}
         */

        this.$commercial_name=$main.find("input[name='commercial_name']");
        this.$email=$main.find("input[name='email']");

        this.$main.on("submit",function(e){
            e.preventDefault();
            me.loading();

            let data = {};
            data.commercial_name = me.$commercial_name.val();
            data.email = me.$email.val();

            api.me.locationCreate(
                data,
                function (messages) {
                    me.displayMessage(messages);
                    setTimeout(function(){
                        document.location=LayoutVars.allFormsUrl;
                    },1000*1);
                },
                function(messages){
                    me.displayErrors(messages);
                }
            );
        });
    }

    static initFromDom(){
        $body.find("[data-model-create-location='']").each(function () {
            $(this).attr("data-model-create-location","init");
            new LocationCreate($(this));
        })
    }
}