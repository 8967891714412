import AbstractForm from "./AbstractForm";

export default class LocationDelete extends AbstractForm{
    /**
     *
     * @param {jQuery} $main
     */
    constructor($main){
        super($main);
        console.log("init LocationDelete");
        let me=this;
        /**
         * Le conteneur pour les saisies utilisateur
         * @type {jQuery}
         */

        this.$uid=$main.find("input[name='locationUid']");

        this.$main.on("submit",function(e){
            e.preventDefault();
            me.loading();

            api.me.locationDelete(
                me.$uid.val(),
                function (messages) {
                    me.displayMessage(messages);
                    setTimeout(function(){
                        document.location=LayoutVars.allFormsUrl;
                    },1000*1);
                },
                function(messages){
                    me.displayErrors(messages);
                }
            );
        });
    }

    static initFromDom(){
        $body.find("[data-model-delete-location='']").each(function () {
            $(this).attr("data-model-delete-location","init");
            new LocationDelete($(this));
        })
    }
}