import Api from "./Api";

export default class ApiMe extends Api{

    constructor(){
        super();
        let me=this;
        //ping
        this.setPingRefreshSlow();
        this._pingTimeout=null;

        this._pingId=null;

        //set interval pour contourner les erreurs dans la récusion
        setInterval(function(){
            me.doPing();
        },120*1000);
        this.doPing();
    }
    /**
     * Accélère le refresh du ping (30 secondes)
     */
    setPingRefreshFast(){
        this._pingRefreshDuration=30*1000;
    }
    /**
     * Accélère le refresh du ping (10 secondes)
     */
    setPingRefreshFaster(){
        this._pingRefreshDuration=10*1000;
    }
    /**
     * Ralentit le refresh du ping
     */
    setPingRefreshSlow(){
       this._pingRefreshDuration=60*1000;
    }
    /**
     * Fait un ping et le relance en récursif
     */
    doPing(){
        let me=this;
        me._ping(function(json){
            console.log("ping success");
            //gestion connexton ou pas
            if(me.isLogged !== json.isLogged){
                console.log("islogged différent",me.isLogged,json.isLogged);
                document.location.reload(true);
                return;//on recharge la page et ciao
            }

            //appel récusif
            if(me._pingTimeout){
                clearTimeout(me._pingTimeout);
            }
            me._pingTimeout=setTimeout(
                function(){
                    me.doPing();
                },me._pingRefreshDuration
            );
        }
        )
    }

    /**
     * Le réel appel au ping serveur
     * @param cbSuccess
     * @private
     */
    _ping(cbSuccess){
        this._call("me","ping",{
            },
            function(messages,html,json){
                cbSuccess(json)
            });
    }

    /**
     * Pour editer une location
     * @param modelUid
     * @param data
     * @param cbSuccess
     * @param cbError
     */
    locationEdit(modelUid, data, cbSuccess, cbError)
    {
        data.uid=modelUid;
        this._call(
            "me",
            "location-edit",
            {
                data:data
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour creer une location
     * @param data
     * @param cbSuccess
     * @param cbError
     */
    locationCreate(data, cbSuccess, cbError)
    {
        this._call(
            "me",
            "location-add",
            {
                data:data
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour delete une location
     * @param uid
     * @param cbSuccess
     * @param cbError
     */
    locationDelete(uid, cbSuccess, cbError)
    {
        this._call(
            "me",
            "location-delete",
            {
                uid:uid
            },
            cbSuccess,
            cbError
        );
    }
}